<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6 medium-offset-3">
        <h1>Add Viewing Room</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-12 medium-6 medium-offset-3">
        <label>Title</label>
        <input v-model="title" type="text" />
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-12 medium-6 medium-offset-3">
        <button @click="saveViewingRoom" class="button">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Room',
  data() {
    return {
      title: '',
    };
  },
  methods: {
    saveViewingRoom() {
      const postData = {};
      postData.title = this.title;
      axios.post(`/viewingRooms/add.json?token=${this.$store.state.token}`, postData)
        .then((response) => {
          this.$router.push(`/add-main-image/${response.data.id}`);
        });
    },
  },
};
</script>
